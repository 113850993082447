<template>
  <div class="mainform">
    <div class="tableList">
    <div class="table">
        <el-table :data="tableData" ref="tabledeatailData">
            <el-table-column prop="templateCode" label="序号" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="templateName" label="评分细项"  :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="type" label="得分"  :show-overflow-tooltip="true">
                <template #default="scope">
                    <el-input  v-model="scope.row.aa"></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="type" label="备注"  :show-overflow-tooltip="true">
                <template #default="scope">
                    <el-input  v-model="scope.row.aa"></el-input>
                </template>
            </el-table-column>
        </el-table>
    </div>
    </div>
    <dialog-box ref="addDialog"  :dialogShow="DialogShow" @handleClose='handleClose' @confirmAdd= "confirmAdd"  title="评分细项" componentName="GradingDialog"
      :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'confirmAdd', type:'primary', size:'mini'}]">
    </dialog-box>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { request } from '@/assets/js/http.js'
export default {
  components: { ...Component.components },
  name: 'GradingDialog',
  data () {
    return {
      DialogShow: false,
      showFormData: {},
      tableData: [
        { templateCode: '11' }
      ],
      form: {
        aaa: ''
      }
    }
  },
  created () {
    // this.myBidData()
  },
  methods: {
    buttonClick: function (data) {
      this.DialogShow = true
    },
    blockup () {

    },
    scoreDetailBtn () { // 评分细项
      this.DialogShow = true
    },
    handleClose () {
      this.DialogShow = false
    },
    confirmAdd () {
      this.DialogShow = false
    },
    myBidData () {
      const obj = {
        supplier: this.childData.searchData.supplier,
        type: this.childData.searchData.type
      }
      request('/api/quality/data/list', 'GET', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
        }
      })
    },
    back: () => {
      window.history.back()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
</style>
